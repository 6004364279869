import React from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import { ImageWithBasePath } from '../../../core/img/ImageWithBasePath';
import { Accordingwithtext, ImageWithTitleCard, AboutusTitlesubtitle, TitleSubtitleDescription } from './about_us_extra';
import Slider from 'react-slick';
import PricingPlanSection from './pricing_plan';
import { ImageComponentCustom } from '../images/image';
import './style.css'

interface GeneralHeaderProps {
    Pagename: string;
}

const clientSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

const GeneralHeaderSection: React.FC<GeneralHeaderProps> = (props) => {
    const routes = all_routes;
    return (
        <React.Fragment>
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <TitleSubtitleDescription title={props.Pagename} alignment='center' classname='padding-custom-zero' />
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={routes.homeOne}>Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {props.Pagename}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const AboutSecIntro: React.FC = () => {

    const paratext = [
        {
            subtitle: 'Welcome to “Take A No”, our first innovative venture from SAFA Logics. Empowering local service providers is at the core of our platform. With a user friendly interface, service providers can easily register with minimal details and efficiently manage their profiles.'
        },
        {
            subtitle: 'Adding and updating services is a simple process, allowing them to showcase their offerings seamlessly. Consumers benefit by booking services at their convenience, eliminating the need for calls or waiting in queues.'
        }
    ]
    const listtextone = [{ subtitle: "Time Efficiency" },
    { subtitle: "Effortless Brand Exposure" }

    ]
    const listtexttwo = [
        { subtitle: "Access Local Services" },
        { subtitle: "Economic Opportunities" }
    ]

    const combinedlist = [...listtextone, ...listtexttwo];
    return (
        <React.Fragment>
            <div className="about-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <ImageComponentCustom src={'assets/img/about-05.png'} alt='Image' />
                        </div>
                        <div className="col-lg-6">
                            <AboutusTitlesubtitle bulletpoints={true} title={'Improve the quality of life with a few clicks'} subtitle={paratext} bulletpointstext={combinedlist} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const WorkAboutSection: React.FC = (props) => {
    return (
        <React.Fragment>
            <section className="work-section work-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading">
                                <TitleSubtitleDescription title='How It Works' alignment='center' />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ImageWithTitleCard title={'Download and Register'} subtitle={'Say goodbye to lengthy queues and effortlessly schedule the services you require.'} number={'01'} imgsrc={'assets/img/icons/work-icon.svg'} />
                        <ImageWithTitleCard title={'Find What You Want'} subtitle={'Select your preferred day and time for a smooth and seamless experience.'} number={'02'} imgsrc={'assets/img/icons/find-icon.svg'} />
                        <ImageWithTitleCard title={'Scan, Confirm, and Delight'} subtitle={'On your booking day, arrive, scan the QR code, and enjoy your reserved services.'} number={'03'} imgsrc={'assets/img/icons/place-icon.svg'} />
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

const AboutSecReview: React.FC = () => {
    return (
        <React.Fragment>
            <section className="client-section hide">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="section-heading aos" data-aos="fade-up">
                                <h2>What our client says</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="testimonial-slider">
                                <Slider {...clientSlider}>
                                    <div className="client-widget aos" data-aos="fade-up">
                                        <div className="client-img">
                                            <Link to={'/'}>
                                                <ImageWithBasePath
                                                    className="img-fluid"
                                                    alt="Avatar"
                                                    src="assets/img/profiles/avatar-01.jpg"
                                                />
                                            </Link>
                                        </div>
                                        <div className="client-content">
                                            <div className="rating">
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                            </div>
                                            <p>
                                                {`Discovering "Take A No" was a game-changer for my business! It's the perfect platform for listing my services, reaching new clients, and streamlining bookings. Thanks to "Take A No", my business has experienced significant growth!`}
                                            </p>
                                            <h5>Sophie Moore</h5>
                                            <h6>Director</h6>
                                        </div>
                                    </div>
                                    <div className="client-widget aos" data-aos="fade-up">
                                        <div className="client-img">
                                            <Link to={'/'}>
                                                <ImageWithBasePath
                                                    className="img-fluid"
                                                    alt="Avat"
                                                    src="assets/img/profiles/avatar-02.jpg"
                                                />
                                            </Link>
                                        </div>
                                        <div className="client-content">
                                            <div className="rating">
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                            </div>
                                            <p>
                                                {`"Take A No" has truly elevated my business! With its user-friendly platform for listing services and attracting new clients, I've seen remarkable growth. I'm sincerely grateful for the positive impact "Take A No" has had on my business.`}
                                            </p>
                                            <h5>Mike Hussy</h5>
                                            <h6>Lead</h6>
                                        </div>
                                    </div>
                                    <div className="client-widget aos" data-aos="fade-up">
                                        <div className="client-img">
                                            <Link to={'/'}>
                                                <ImageWithBasePath
                                                    className="img-fluid"
                                                    alt="Avat"
                                                    src="assets/img/profiles/avatar-03.jpg"
                                                />
                                            </Link>
                                        </div>
                                        <div className="client-content">
                                            <div className="rating">
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                                <i className="fas fa-star filled"></i>
                                            </div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit, sed do eiusmod tempor incididunt ut labore et
                                                dolore magna aliqua. Ut enim ad minim veniam, quis
                                                nostrud exercitation ullamco laboris nisi{' '}
                                            </p>
                                            <h5>John Doe</h5>
                                            <h6>CEO</h6>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

const AboutSecChooseus: React.FC = () => {
    const accordiantext = [{
        title: 'Cross Platform', subtitle: 'Our app seamlessly adapts to Android and iOS platforms, ensuring a consistent experience with robust functionality and a user friendly interface. Our goal is to offer you a versatile, dependable, and enjoyable user experience across both platforms.', to: 'aboutone'
    },
    {
        title: 'Effortless booking', subtitle: 'Discover the benefits of speed and simplicity on our platform. We assure fast transactions and easy bookings, ensuring a seamless and convenient overall booking experience. Give our services a try today and elevate the way you book!', to: 'abouttwo'
    },
    {
        title: 'Zero wait guarantee', subtitle: 'We understand the value of your time, and waiting in lines can be a source of frustration. With our zero-wait guarantee, we eliminate the inconvenience of queues and delays, ensuring a prompt service experience through our consumer app.', to: 'aboutthree'
    },
    {
        title: 'Booking with Peace of Mind', subtitle: ' Our Queue Booking app takes server and app security to the next level, ensuring your data is as safe. Our app adheres to the latest security standards, incorporating secure APIs for seamless communication between the app and server.', to: 'aboutfour'
    },

    ]
    return (
        <React.Fragment>
            <div className="chooseus-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="choose-content">
                            <TitleSubtitleDescription title='Why Choose Us' description={['Time and Convenience with Take A No']} alignment='left' classname='about-heading-custom-padding' />
                                {accordiantext.map((item, index) => (
                                    <Accordingwithtext key={index} title={item.title} subtitle={item.subtitle} to={item.to} />

                                ))}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <ImageComponentCustom src='assets/img/about-04.png' classname='chooseus-img' imgclassname='img-fluid' alt='Image' />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const AboutUsSec: React.FC = () => {
    return (
        <React.Fragment>
            <div className="content p-0">
                <GeneralHeaderSection Pagename={"About Us"} />
                <AboutSecIntro />
                <WorkAboutSection />
                <PricingPlanSection />
                <AboutSecChooseus />
                <AboutSecReview />
            </div>
        </React.Fragment>
    )
}
export { GeneralHeaderSection, AboutSecIntro, WorkAboutSection, AboutSecReview, AboutSecChooseus, AboutUsSec }