import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { TKN_DropdownInputvalue, TKN_TextInputreqired, TKN_TextInput } from '../../../components/inputfields/input';
import { CountryDropdown } from "react-country-region-selector";
import { validateZipCodeByCountry } from "../../../../helper/validate";
import apiService from '../../../../api/authentication-service';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux'
import { setVendorRole } from '../../../../core/data/redux/action';
import { format } from 'date-fns';
import { CHAR_LIMIT_ADDRESS } from '../../../../constants/constants';
import en from '../../../../en/en';

type ErrorMessages = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  gender?: string;
  address1?: string;
  address2?: string;
  state?: string;
  city?: string;
  postcode?: string;
  selectedCountry?: string;
};

type Option = {
  label: string;
  value: number;
};

const ProviderProfile = () => {

  const routes = all_routes;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [error, setError] = useState('');
  const [birthDate, setBirthDate] = useState<Nullable<Date>>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [genderoption, setGenderoption] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [countryError, setCountryError] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const vendorEmail = useSelector((state: RootState) => state.vendorEmail);

  const gender: Option[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 }
  ];

  const resetResponseMessage = () => {
    setResponseMessage('');
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
  };

  const handleAddressChange = (value: string) => {
    if (value.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress1(value)
      errors.address1 = '';
    } else {
      errors.address1 = en.CHAR_LIMIT;
    }
    if (errors.address1) {
      setErrors((prevErrors) => ({ ...prevErrors, address1: undefined }));
    }
  };

  const handleAddress2Change = (value: string) => {
    if (value.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress2(value)
      errors.address2 = '';
    } else {
      errors.address2 = en.CHAR_LIMIT;
    }
    if (errors.address2) {
      setErrors((prevErrors) => ({ ...prevErrors, address2: undefined }));
    }
  };

  const handleStateChange = (value: string) => {
    setState(value);
  };

  const handleCityChange = (value: string) => {
    setCity(value);
  };

  const handlePostalcodeChange = (value: string) => {
    setPostcode(value);
  };

  const handleSelectgender = (selectedGender: Option) => {
    setGenderoption(selectedGender.label);
  };

  const handleCountryChange = (val: string) => {
    resetResponseMessage();
    if (!val) {
      setCountryError('Unable to select country. Please try again.');
      setSelectedCountry(null);
    } else {
      setCountryError('');
      setSelectedCountry(val);
    };
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedFile(reader.result as string);
      };

      reader.readAsDataURL(file);
    };
  };

  const handleSubmit = () => {
    const newErrors: ErrorMessages = {};

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!genderoption) newErrors.gender = 'Gender is required';
    if (!address1) newErrors.address1 = 'Address 1 is required';
    if (!state) newErrors.state = 'State is required';
    if (!city) newErrors.city = 'City is required';
    if (!postcode) {
      newErrors.postcode = 'Postcode is required';
    } else {
      const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, postcode);
      if (zipCodeValidationResult.status === 'false') {
        newErrors.postcode = zipCodeValidationResult.error;
      };
    };
    if (!selectedCountry) newErrors.selectedCountry = 'Country is required';
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      sendProfileRequest();
    };
  };

  const sendProfileRequest = () => {
    const payload = profileRequest();

    apiService.addProfile(payload)
      .then((response: any) => {
        getProfileResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setError('Failed to fetch user details');
      });
  };

  const profileRequest = () => ({
    user_id: vendorEmail.userid,
    email: vendorEmail.email,
    first_name: firstName,
    last_name: lastName,
    gender: genderoption,
    dob: birthDate ? format(birthDate, 'yyyy-MM-dd') : null,
    address1: address1,
    address2: address2,
    country: selectedCountry,
    state: state,
    city: city,
    zipcode: postcode,
    profile_verified: 1,
    profile_photo: selectedFile ? selectedFile.split(',')[1] : '',
  });

  const getProfileResponse = (response: any) => {

    if (response) {
      const status = response?.status;

      if (status === true) {
        const user_role = response?.data?.userrole;
        const U_id = response?.data?.id;
        const userRoleDetails = {
          userrole: user_role,
          uid: U_id
        };

        if (user_role) {
          dispatch(setVendorRole(userRoleDetails));
          navigateTo(routes.vendorBusinessProfile);
        };
      };
    };
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="widget-title">
              <h4>Add Profile</h4>
            </div>
            <h6 className="user-title">Profile Picture</h6>
            <div className="pro-picture w-100">
              <div className="pro-img">
                <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="user" />
              </div>
              <div className="pro-info">
                <div className="d-flex">
                  <div className="img-upload">
                    <i className="feather-upload-cloud me-1" />
                    Upload
                    <input type="file" accept="image/*" onChange={handleFileChange} />
                  </div>
                  <Link to="#" className="btn btn-remove">
                    Remove
                  </Link>
                </div>
                <p>
                  *image size should be at least 320px big,and less then 500kb.
                  Allowed files .png and .jpg.
                </p>
              </div>
            </div>
            <h6 className="user-title">General Information</h6>
            <div className="general-info">
              <div className="row">
                <div className="col-md-6">
                  {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                  <TKN_TextInputreqired onChange={handleFirstNameChange} label="First Name" placeholder='Enter first name' />
                </div>
                <div className="col-md-6">
                  {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                  <TKN_TextInputreqired onChange={handleLastNameChange} label="Last Name" placeholder='Enter last name' />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Gender<span className="text-danger">*</span></label>
                    {errors.gender && <div className="text-danger">{errors.gender}</div>}
                    <TKN_DropdownInputvalue
                      placeholderText="Select gender"
                      options={gender}
                      onSelect={handleSelectgender}
                      descriptionError={errors.gender}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Date of birth</label>
                    <div className="form-icon cus-profile">
                      <PrimeCalendar
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date()}
                        value={birthDate}
                        onChange={(e) => {
                          if (e.value) {
                            const date = new Date(e.value);
                            setBirthDate(date);
                          }
                        }}
                        placeholder="MM/DD/YYYY"
                        showIcon
                        inputClassName="p-inputtext"
                        readOnlyInput={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="user-title">Address</h6>
              <div className="general-info">
                <div className="row">
                  <div className="col-md-6">
                    {errors.address1 && <div className="text-danger">{errors.address1}</div>}
                    <TKN_TextInputreqired maxLength={CHAR_LIMIT_ADDRESS} onChange={handleAddressChange} label="Address 1" placeholder='Enter your address' />
                  </div>
                  <div className="col-md-6">
                    {errors.address2 && <div className="text-danger">{errors.address2}</div>}
                    <TKN_TextInput maxLength={CHAR_LIMIT_ADDRESS} onChange={handleAddress2Change} label="Address 2" placeholder='Enter your address' />
                  </div>
                  <div className="col-md-6">
                    {errors.state && <div className="text-danger">{errors.state}</div>}
                    <TKN_TextInputreqired onChange={handleStateChange} label="State" placeholder='Enter your state' />
                  </div>
                  <div className="col-md-6">
                    {errors.city && <div className="text-danger">{errors.city}</div>}
                    <TKN_TextInputreqired onChange={handleCityChange} label="City" placeholder='Enter your city' />
                  </div>
                  <div className="col-md-6">
                    {errors.postcode && <div className="text-danger">{errors.postcode}</div>}
                    <TKN_TextInputreqired onChange={handlePostalcodeChange} label="Postal Code" placeholder='Enter postal code' />
                  </div>
                  <div className="col-md-6">
                    {errors.selectedCountry && <div className="text-danger">{errors.selectedCountry}</div>}
                    <div className="form-group">
                      <label className="col-form-label">Country<span className="text-danger">*</span></label>
                      <CountryDropdown
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        classes="form-control"
                        defaultOptionLabel="Select Country"
                        priorityOptions={['United States']}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="acc-submit">
              <Link to="" className="btn btn-secondary" type='cancel'>
                Cancel
              </Link>
              <Link to="" className="btn btn-primary" type="submit" onClick={handleSubmit}>
                Save
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderProfile;
