import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../../../components/header/home-header';
import FooterOne from '../../../components/footer-one';
import './style.css'

const TermsofUse = () => {
    const location = useLocation();
    const routes = all_routes;

    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);
    
    useEffect(() => {
      if (location.hash) {
        const elementId = location.hash.replace("#", "");
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [location]);
    return (
        <>
            <HomeHeader type={1} />
            {/* Breadcrumb */}
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h2 className="breadcrumb-title">Terms &amp; Condition</h2>
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={routes.homeOne}>Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Terms &amp; Condition
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Breadcrumb */}
            <div className="content">
                <div className="container">
                    <div className="row">
                        {/* Terms & Conditions */}
                        <div className="col-md-12">
                            <div className="terms-content">
                                <p className='terms-li '>Last Modified: April 09, 2024</p>
                                <hr />
                                <p className='terms-subheading'><b>Acceptance of Terms :</b></p>
                                <p className='text-align-justify'>By accessing and using the services provided by SAFA logics, including the “Take A No” mobile app, you agree to comply with and be bound by these terms and conditions.</p>
                                <p className='terms-subheading'><b>User Registration :</b></p>
                                <p className='text-align-justify'>To access certain features and services within the “Take A No” app, users are required to register. During registration, a valid email address is necessary. This email address will be used for account management, communication, and providing important updates.</p>
                                <p className='terms-subheading'><b>Processing of your data :</b></p>
                                <p className='text-align-justify'>By accessing and using the services provided by SAFA logics, including the “Take A No” mobile app, you agree to comply with and be bound by these terms and conditions.</p>
                                <ul>
                                    <li className='text-align-justify terms-li'>Account verification and activation.</li>
                                    <li className='text-align-justify terms-li'>Communication regarding appointment bookings, cancellations, and modifications.</li>
                                    <li className='text-align-justify terms-li'>Sending important announcements, promotions, and relevant information related to the “Take A No” app.</li>
                                </ul>
                                <p className='terms-subheading'><b>Family Member Addition :</b></p>
                                <p className='text-align-justify'>Users have the option to add family members within the app for streamlined appointment scheduling. By doing so, users affirm that they have the explicit consent of the family members to share their information and use the app on their behalf.</p>
                                <h2 className='terms-heading'>Authorized user accounts and account security</h2>
                                <p className='terms-subheading'><b>Data Security :</b></p>
                                <p className='text-align-justify'>SAFA Logics is committed to safeguarding user information, including email addresses. We employ industry-standard security measures to protect against unauthorized access, disclosure, alteration, and destruction of personal data.</p>
                                <p className='terms-subheading'><b>User Responsibilities :</b></p>
                                <p className='text-align-justify'>Users are responsible for maintaining the confidentiality of their account information, including the associated email address. Any activity occurring under the user’s account is their responsibility. It is the duty of the customer to verify the legitimacy of the service providers. The “Take A No” app is designed to offer a smooth time management solution for both clients and vendors; it will not verify the legitimacy of the vendors or their services. Respective parties are in charge of confirming with good judgment.</p>
                                <p className='terms-subheading'><b>Service Availability :</b></p>
                                <p className='text-align-justify'>While SAFA Logics strives to provide a reliable and uninterrupted service, we do not guarantee the continuous availability of the “Take A No” app. Maintenance activities or unforeseen circumstances may result in temporary service interruptions.</p>
                                <p className='terms-subheading'><b>Changes to Terms :</b></p>
                                <p className='text-align-justify'>SAFA Logics reserves the right to update or modify these terms and conditions at any time. Users will be notified of any significant changes, and continued use of the app implies acceptance of the updated terms.</p>
                                <h2 className='terms-heading'>Data Collection and Privacy</h2>
                                <p className='terms-subheading'><b>Personal Information :</b></p>
                                <p className='text-align-justify'>By using the “Take A No” mobile app provided by SAFA Logics, you agree to the collection and use of your personal information as described in this section.</p>
                                <p className='terms-subheading'><b>Information Collected :</b></p>
                                <p className='text-align-justify'>The app may collect certain personal information, including but not limited to your email address, name, and phone number. This information is collected to enhance your user experience, facilitate appointment bookings, and allow service providers to add and manage their services.</p>
                                <p className='terms-subheading'><b>Usage of Information :</b></p>
                                <p className='text-align-justify'>Your personal information may be used to:</p>
                                <ul>
                                    <li className='text-align-justify terms-li '>Facilitate the booking and management of appointments.</li>
                                    <li className='text-align-justify terms-li '>Provide personalized services based on your preferences.</li>
                                    <li className='text-align-justify terms-li '>Communicate important updates, promotions, and relevant information.</li>
                                    <li className='text-align-justify terms-li '>Allow service providers to contact you for appointment-related purposes.</li>
                                </ul>
                                <p className='terms-subheading'><b>Protection of Information :</b></p>
                                <p className='text-align-justify'>SAFA Logics is committed to protecting your personal information. We employ industry-standard security measures to safeguard your data from unauthorized access, disclosure, alteration, and destruction.</p>
                                <p className='terms-subheading'><b>Third-Party Services :</b></p>
                                <p className='text-align-justify'>The app may integrate with third-party services for enhanced functionality. Please be aware that these services may have their own privacy policies, and SAFA Logics is not responsible for their practices.</p>
                                <p className='terms-subheading'><b>Data Sharing :</b></p>
                                <p className='text-align-justify'>Your personal information will not be shared with third parties for marketing purposes without your explicit consent. However, aggregated and anonymized data may be used for analytical purposes.</p>
                                <p className='terms-subheading'><b>User Control :</b></p>
                                <p className='text-align-justify'>You have the right to access, modify, or delete your personal information from our records. For assistance with these requests, please contact us at support@safalogics.com.</p>
                                <p className='terms-subheading'><b>Policy Changes :</b></p>
                                <p className='text-align-justify'>SAFA Logics reserves the right to update or modify this data collection and privacy policy. Users will be notified of any significant changes, and continued use of the app implies acceptance of the updated terms. By using the “Take A No” app, you acknowledge and agree to the terms outlined in this data collection and privacy policy.</p>
                                <p className='terms-subheading'><b>Termination of Accounts :</b></p>
                                <p className='text-align-justify'>SAFA Logics reserves the right to terminate or suspend user accounts for violation of these terms or any other reason deemed necessary for the proper functioning and integrity of the “Take A No” app.</p>
                                <h2 id="refund-policy" className='terms-heading'>Refund Policy</h2>
                                <p className='text-align-justify'>We strive to provide the best experience for our users. Please review our refund policy before purchasing a subscription.</p>
                                <p className='terms-subheading'><b>No Refunds for Used Services :</b></p>
                                <p className='text-align-justify'>Once a subscription is activated and used, we do not offer refunds or credits for partially used billing periods.</p>
                                <p className='terms-subheading'><b>Technical Issues :</b></p>
                                <p className='text-align-justify'>If you experience technical issues that prevent you from using the app, please contact our support team. Refunds may be granted if the issue cannot be resolved.</p> 
                                <p className='terms-subheading'><b>Cancellation Policy :</b></p>
                                <p className='text-align-justify'>You may cancel your subscription at any time, but cancellation will only prevent future charges; it does not trigger a refund for the current billing period.</p>
                                <p className='text-align-justify'>For any refund inquiries, please contact our support team at support@takeano.com</p>
                                <p className='terms-subheading'><b>Contact Information :</b></p>
                                <p className='text-align-justify'>For questions or concerns about these terms and conditions, please contact us at support@safalogics.com.</p>
                                <h2 className='terms-lastcontent'>By using the services provided by SAFA Logics, you acknowledge and agree to the terms outlined in this document.</h2>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <FooterOne />
        </>
    );
};

export default TermsofUse;
