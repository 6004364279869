import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { all_routes } from '../../core/data/routes/all_routes';
import HomeOne from '../frontend/pages/home/home-one';
import Pages from '../frontend/pages/pages';
import Customers from '../frontend/customers/customers';
import Services from '../frontend/services/services';
import Blog from '../frontend/pages/blog/blog';
import LoginPhone1 from '../frontend/pages/authentication/login-phone1';
import Booking2 from '../frontend/pages/booking/booking-2';
import PhoneOtp from '../frontend/pages/authentication/phone-otp';
import Booking1 from '../frontend/pages/booking/booking-1';
import BookingDetails from '../frontend/pages/booking/booking-details';
import BookingDone from '../frontend/pages/booking/booking-done';
import BookingPayment from '../frontend/pages/booking/booking-payment';
import ChooseSignup from '../frontend/pages/authentication/choose-signup';
import Error404 from '../frontend/pages/Error page/error404';
import UserSignup from '../frontend/pages/authentication/user-signup';
import ProviderSignup from '../frontend/pages/authentication/provider-signup';
import EmailOtp from '../frontend/pages/authentication/email-otp';
import VendorProfile from '../frontend/pages/authentication/provider-profile';
import UserProfile from '../frontend/pages/authentication/user-profile';
import VendorBusinnessprofile from '../frontend/pages/authentication/provider-business-profile';
import Login from '../frontend/pages/authentication/login';
import BookingLogin from '../frontend/pages/authentication/bookinglogin';
import PaymentSetting from '../frontend/providers/settings/payment-setting';
import ResetPassword from '../frontend/pages/authentication/reset-password';
import PasswordRecovery from '../frontend/pages/authentication/password-recovery';
import Success from '../frontend/pages/authentication/success';
import FreeTrail from '../frontend/pages/authentication/free-trail';
import Providers from '../frontend/providers/providers';
import TermsofUse from '../frontend/pages/terms/terms';
import Contact_Us from '../frontend/pages/contact/contact';
import ForgotPasswordOtp from '../frontend/pages/authentication/forgotpassword-otp';
import ServiceGrid from '../frontend/services/service-grid/service-grid';
import AboutUs from '../frontend/pages/about-us/about-us';
import Faq from '../frontend/pages/faq/faq';
import ServiceDetails1 from '../frontend/services/service-details/service-details1';
import ServiceDetails2 from '../frontend/services/service-details/service-details2';
import PrivacyPolicy from '../frontend/pages/privacy-policy/privacy-policy';
import AppSubscriptionPayment from '../frontend/pages/app-subscription-purchase/app-subscription-purchase';
import { SubscriptionPurchaseStatusSuccess, SubscriptionPurchaseStatusFailed, SubscriptionPurchaseCancel } from '../frontend/pages/subscription-purchase-status/subscription-purchase-status';

const routes = all_routes;
const publicRoutes = [
  {
    path: '/',
    name: 'Root',
    element: <HomeOne />,
    route: Route,
  },
  {
    path: '*',
    name: 'NotFound',
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    path: routes.booking1,
    name: 'booking-1',
    element: <Booking1 />,
    route: Route,
  },
  {
    path: routes.booking2,
    name: 'booking-2',
    element: <Booking2 />,
    route: Route,
  },
  {
    path: routes.bookingDetails,
    name: 'booking-details',
    element: <BookingDetails />,
    route: Route,
  },
  {
    path: routes.bookingDone,
    name: 'booking-done',
    element: <BookingDone />,
    route: Route,
  },
  {
    path: routes.bookingPayment,
    name: 'booking-payment',
    element: <BookingPayment />,
    route: Route,
  },
  {
    path: routes.vendorProfile,
    name: 'provider-profile',
    element: <VendorProfile />,
    route: Route,
  },
  {
    path: routes.userProfile,
    name: 'user-profile',
    element: <UserProfile />,
    route: Route,
  },
  {
    path: routes.vendorBusinessProfile,
    name: 'provider-business-profile',
    element: <VendorBusinnessprofile />,
    route: Route,
  },
  {
    path: routes.error404,
    name: 'error404',
    element: <Error404 />,
    route: Route,
  },
  {
    path: routes.error500,
    name: 'error404',
    element: <Error404 />,
    route: Route,
  },
  {
    path: routes.paymentSetting,
    name: 'payment-setting',
    element: <PaymentSetting />,
    route: Route,
  },
  {
    path: routes.pages,
    name: 'pages',
    element: <Pages />,
    route: Route,
  },
  {
    path: routes.customers,
    name: 'customers',
    element: <Customers />,
    route: Route,
  },
  {
    path: routes.services,
    name: 'services',
    element: <Services />,
    route: Route,
  },
  {
    path: routes.blog,
    name: 'blog',
    element: <Blog />,
    route: Route,
  },
  {
    path: routes.vendors,
    name: 'providers',
    element: <Providers />,
    route: Route,
  },
  {
    path: '/authentication/reset-password',
    name: 'reset-password',
    element: <ResetPassword />,
    route: Route,
  },
  {
    path: '/authentication/password-recovery',
    name: 'password-recovery',
    element: <PasswordRecovery />,
    route: Route,
  },
  {
    path: '/authentication/login',
    name: 'login',
    element: <Login />,
    route: Route,
  },
  {
    path: '/authentication/bookinglogin',
    name: 'login',
    element: <BookingLogin />,
    route: Route,
  },
  {
    path: '/authentication/login-phone1',
    name: 'login-phone1',
    element: <LoginPhone1 />,
    route: Route,
  },
  {
    path: '/authentication/phone-otp',
    name: 'Phone-Otp',
    element: <PhoneOtp />,
    route: Route,
  },
  {
    path: '/authentication/email-otp',
    name: 'email-Otp',
    element: <EmailOtp />,
    route: Route,
  },
  {
    path: '/authentication/forgotpassword-otp',
    name: 'forgotPasswordOtp',
    element: <ForgotPasswordOtp />,
    route: Route,
  },
  {
    path: '/authentication/choose-signup',
    name: 'choose-signup',
    element: <ChooseSignup />,
    route: Route,
  },
  {
    path: '/authentication/user-signup',
    name: 'user-signup',
    element: <UserSignup />,
    route: Route,
  },
  {
    path: routes.vendorSignup,
    name: 'provider-signup',
    element: <ProviderSignup />,
    route: Route,
  },
  {
    path: '/authentication/success',
    name: 'success',
    element: <Success />,
    route: Route,
  },
  {
    path: '/authentication/free-trail',
    name: 'free-trial',
    element: <FreeTrail />,
    route: Route,
  },
  // {
  //   path: 'admin',
  //   name: 'Root',
  //   element: <Navigate to="/admin/dashboard" />,
  //   route: Route,
  // },
  {
    path: 'terms',
    name: 'terms',
    element: <TermsofUse />,
    route: Route,
  },
  {
    path: 'privacy-policy',
    name: 'privacy-policy',
    element: <PrivacyPolicy />,
    route: Route,
  },
  {
    path: 'contact',
    name: 'contact',
    element: <Contact_Us />,
    route: Route,
  },
  {
    path: routes.search,
    name: 'search',
    element: <ServiceGrid />,
    route: Route,
  },
  {
    path: routes.aboutUs,
    name: 'about-us',
    element: <AboutUs />,
    route: Route
  },
  {
    path: routes.appSubscriptionPayment,
    name: 'app-subscription-payment',
    element: <AppSubscriptionPayment />,
    route: Route
  },
  {
    path: routes.SubscriptionPurchaseStatusSuccess,
    name: 'subscription-purchase-status',
    element: <SubscriptionPurchaseStatusSuccess />,
    route: Route
  },
  {
    path: routes.SubscriptionPurchaseStatusFailed,
    name: 'subscription-purchase-status',
    element: <SubscriptionPurchaseStatusFailed />,
    route: Route
  },
  {
    path: routes.SubscriptionPurchaseCancel,
    name: 'subscription-purchase-status',
    element: <SubscriptionPurchaseCancel />,
    route: Route
  },
  {
    path: routes.faq,
    name: 'faq',
    element: <Faq />,
    route: Route
  },
  {
    path: routes.businessDetails,
    name: 'business-details',
    element: <ServiceDetails1 />,
    route: Route
  },
  {
    path: routes.bookingAppointment,
    name: 'book-appointment',
    element: <ServiceDetails2 />,
    route: Route,
  }
];

export { publicRoutes };
