export const all_routes = {

  // authentication module path
  chooseSignUp: '/authentication/choose-signup',
  emailOtp: '/authentication/email-otp',
  forgotPasswordOtp: '/authentication/forgotpassword-otp',
  userSignup: '/authentication/user-signup',
  vendorSignup: '/authentication/signup',
  vendorProfile: '/authentication/profile',
  userProfile: '/authentication/user-profile',
  vendorBusinessProfile: '/authentication/business-profile',
  login: '/authentication/login',
  bookinglogin: '/authentication/bookinglogin',
  loginemail: '/authentication/login-email',
  loginphone: '/authentication/login-phone',
  loginphone1: '/authentication/login-phone1',
  freeTrail: '/authentication/free-trail',
  passwordRecovery: '/authentication/password-recovery',
  phoneOtp: '/authentication/phone-otp',
  success: '/authentication/success1',
  resetPassword: '/authentication/reset-password',

  // home module path
  homeOne: '/',

  // common routes
  search: '/search',
  aboutUs: '/about-us',
  appSubscriptionPayment: '/app-subscription-payment',
  SubscriptionPurchaseStatusSuccess: '/subscription-purchase-status-success',
  SubscriptionPurchaseStatusFailed: '/subscription-purchase-status-failed',
  SubscriptionPurchaseCancel: '/subscription-purchase-cancel',
  faq: '/faq',
  businessDetails: '/business-details',
  bookingAppointment: '/book-appointment',

  // blog module path
  blog: '/blog/*',
  blogDetails: '/blog/blog-details',
  blogGrid: '/blog/blog-grid',
  blogList: '/blog/blog-list',

  // services module path
  services: '/services/*',
  geoLocationMap: '/services/geoLocationMap',
  serviceList: '/services/service-list',
  createService: '/services/create-service',
  vendorDetails: '/services/vendors/details',
  searchList: '/services/search-list',
  vendor: '/services/vendors/list',

  // customers module path
  customers: '/customers/*',
  customerDashboard: '/customers/customer-dashboard',
  customerBooking: '/customers/booking',
  customerFavourite: '/customers/favourite',
  customerNotifications: '/customers/notifications',
  customerProfile: '/customers/profile',
  customerBookingCalendar: '/customers/booking-calendar',
  customerChat: '/customers/chat',
  customerReviews: '/customers/reviews',
  customerWallet: '/customers/wallet',
  deviceManagement: '/customers/settings/device-management',
  invoice: '/customers/invoice',
  commonNotification: '/customers/notification',
  customerReportAnIssue: '/customers/report-an-issue',
  customerResetPassword: '/customers/reset-password',
  customerAddFamily: '/customers/add-family-members',
  customerEditFamily: '/customers/edit-family-members',
  customerComingSoon: '/customers/coming-soon',
  customerEditProfile: '/customers/edit-profile',
  customerDeleteAccount: '/customers/delete-account',
  customerAddMember: '/customers/family-members-list',

  // vendors module path
  vendors: '/vendors/*',
  vendorAddon: '/vendors/addon',
  verfication: '/vendors/verification',
  vendorPlan: '/vendors/plan',
  vendorProfileSettings: '/vendors/edit-personal-profile',
  vendorProfileEdit: '/vendors/profile',
  vendorBusinessProfileEdit: '/vendors/edit-business-profile',
  paymentSetting: '/vendors/payment-setting',
  connectedApp: '/vendors/connected-app',
  vendorHoliday: '/vendors/holiday',
  vendorNotification: '/vendors/notification',
  vendorOffer: '/vendors/offer',
  vendorDeviceManagement: '/vendors/device-management',
  vendorLoginActivity: '/vendors/login-activity',
  vendorEarnings: '/vendors/earnings',
  vendorAppointmentSettings: '/vendors/edit-profile',
  vendorDeleteAccount: '/vendors/delete-account',
  TKN_modal: '/vendors/delete-account',
  vendorConnectedApps: '/vendors/connected-apps',
  vendorAvailability: '/vendors/availability',
  vendorBookDetails: '/vendors/book-details',
  vendorBooking: '/vendors/booking',
  vendorChat: '/vendors/chat',
  vendorCoupons: '/vendors/coupons',
  vendorDashboard: '/vendors/vendor-dashboard',
  vendorsignupPayment: '/vendors/signup-payment',
  vendorsignupSubscription: '/vendors/signup-subscription',
  vendorsocialProfile: '/vendors/social-profile',
  vendorsubscription: '/vendors/subscription',
  vendorPayout: '/vendors/payout',
  vendorWalkin: '/vendors/walkin',
  vendorReview: '/vendors/review',
  vendorservice: '/vendors/service',
  vendorserviceList: '/vendors/service-list',
  vendorsecuritySettings: '/vendors/security-settings',
  addSubscription: '/vendors/add-subscription',
  vendorReportAnIssue: '/vendors/report-an-issue',
  vendorDeactivate: '/vendors/deactivate',
  vendorResetPassword: '/vendors/reset-password',
  vendorCustomerDashboard: '/vendors/dashboard',
  vendorAddService: '/vendors/add-service',
  vendorEditService: '/vendors/edit-service',
  vendorComingSoon: '/vendors/coming-soon',
  qrCodeScreen: '/vendors/qr-code',
  vendorSubscriptionPaymentCheckout: '/vendors/subscription-payment-checkout',
  vendorPaymentStatus: '/vendors/payment-status',

  // pages module path
  pages: '/pages/*',
  error404: '/error/error-404',
  error500: '/error/error-500',
  howItWorks: '/pages/how-it-works',
  termsCondition: '/terms',
  contactUs: '/contact',
  booking1: '/pages/booking/booking-1',
  booking2: '/pages/booking/booking-2',
  bookingDetails: '/pages/booking/booking-details',
  bookingDone: '/pages/booking/booking-done',
  bookingPayment: '/pages/booking/booking-payment',
  comingSoon: '/pages/coming-soon',
  categories: '/pages/categories',
  privacyPolicy: '/privacy-policy',
  pricingPlan: '/pages/pricing-plan',
  sessionExpired: '/pages/session-expired',
  maintenance: '/pages/maintenance',
  installer: '/pages/installer',
  test: '',

  // Admin Module Path
  // admin: '/admin/*',
  // abuseReport: '/admin/support/abuse-reports',
  // contactMessages: '/admin/support/contact-messages',
  // contactMessagesView: '/admin/support/contact-messages-view',
  // countries: '/admin/location/countries',
  // cities: '/admin/location/cities',
  // state: '/admin/location/state',
  // connectedApps: '/admin/setting/connected-apps',
  // cashOnDelivery: '/admin/finance-accounts/cash-on-delivery',
  // chat: '/admin/chat',
  // completedBooking: '/admin/booking/completed-booking',
  // pendingBooking: '/admin/pending-booking',
  // banIpAddress: '/admin/setting/ban-ip-address',
  // bankTransferList: '/admin/finance-accounts/bank-transferlist',
  // calendarSetting: '/admin/setting/calendar-settings',
  // blogCategories: '/blog-categories',
  // coupons: '/admin/marketing/coupons',
  // cronJob: '/admin/setting/cronjob',
  // curriencies: '/admin/setting/currencies',
  // createMenu: '/admin/management/create-menu',
  // userCustomer: '/admin/user/customers',
  // adminEarnings: '/admin/reports/admin-earnings',
  // allBlog: '/admin/blog/all-blog',
  // approvedTransferlist: '/admin/finance-accounts/approved-transferlist',
  // appearance: '/admin/setting/appearance',
  // accountSettings: '/admin/setting/account-settings',
  // emailSettings: '/admin/setting/email-settings',
  // appointmentSettings: '/admin/setting/appointment-settings',
  // authenticationSettings: '/admin/setting/authentication-settings',
  // announcements: '/admin/support/announcements',
  // currencySettings: '/admin/setting/currency-settings',
  // customerWallets: '/admin/wallet',
  // availablePlugins: '/admin/plugin/available-plugins',
  // payoutRequest: '/admin/payouts/payout-request',
  // payoutSettings: '/admin/payouts/payout-settings',
  // paymentSettings: '/admin/setting/payment-settings',
  // paymentGateway: '/admin/setting/payment-gateways',
  // headerSettings: '/admin/setting/header-settings',
  // inActiveBlog: '/admin/blog/inactive-blog',
  // inActiveServices: '/admin/services/inactive-services',
  // addServices: '/admin/services/add-service',
  // allServices: '/admin/services/all-services',
  // editService: '/admin/services/edit-services',
  // inProgressBooking: '/admin/booking/inprogress-booking',
  // pendingServices: '/admin/services/pending-services',
  // dashboard: '/admin/dashboard',
  // websiteSettings: '/admin/management/website-settings',
  // emailNewsletter: '/admin/marketing/email-newsletter',
  // language: '/admin/setting/language',
  // loginActivity: '/admin/setting/login-activity',
  // localization: '/admin/setting/localization',
  // seoSettings: '/admin/setting/seo-settings',
  // serviceSettings: '/admin/setting/service-settings',
  // siteInformation: '/admin/setting/site-information',
  // smsSettings: '/admin/setting/sms-settings',
  // menuManagement: '/admin/management/menu-management',
  // salesReport: '/admin/reports/service-sales',
  // socialAuthentication: '/admin/setting/social-authentication',
  // socialProfile: '/admin/setting/social-profile',
  // subCategories: '/admin/sub-categories',
  // taxRates: '/admin/setting/tax-rates',
  // typographytSettings: '/admin/setting/typography-settings',
  // testimonials: '/admin/content/testimonials',
  // wallet: '/admin/finance-accounts/wallet',
  // verificationRequest: '/admin/verification-request',
  // systemInformation: '/admin/setting/system-information',
  // smsTemplate: '/admin/management/sms-template',
  // storageSetting: '/admin/setting/storage-settings',
  // pluginManager: '/admin/management/plugin-manager',
  // preferenceSettings: '/admin/setting/preference-settings',
  // vendorEarning: '/admin/reports/earnings',
  // uservendors: '/admin/users/vendors',
  // vendorsales: '/admin/reports/sales',
  // vendorsettings: '/admin/setting/settings',
  // vendorWallet: '/admin/reports/wallet',
  // faqadmin: '/admin/content/faq',
  // featureServices: '/admin/marketing/featured-services',
  // systemBackup: '/admin/setting/system-backup',
  // gdpr: '/admin/setting/gdbr',
  // emailTemplates: '/admin/management/email-templates',
  // review: '/admin/review',
  // reviewType: '/admin/review-type',
  // roles: '/admin/roles',
  // salestransactions: '/admin/finance-accounts/sales-transactions',
  // walletHistory: '/admin/wallet-history',
  // membershipTransaction: '/admin/reports/membership-transaction',
  // addHome: '/admin/pages/add-home',
  // addMembership: '/admin/membership/add-membership',
  // activeServices: '/admin/services/active-services',
  // deletedServices: '/admin/services/deleted-services',
  // security: '/admin/setting/security',
  // registerreport: '/admin/reports/register-report',
  // successTransferlist: '/admin/finance-accounts/successful-transferlist',
  // footerSettings: '/admin/setting/footer-settings',
  // membership: '/admin/membership',
  // membershipaddons: '/admin/memberships/membership-addon',
  // users: '/admin/users',
  // usersCustomer: '/admin/customers',
  // deleteAccountReqest: '/admin/delete-account-requests',
  // viewServices: '/admin/view-service',
  // pendingBlog: '/admin/blog/pending-blog',
  // booking: '/admin/booking',
  // cancelledBooking: '/admin/bookings/cancelled-booking',
  // editblog: '/admin/edit-blog',
  // pendingTransferlist: '/admin/finance-accounts/pending-transferlist',
  // rejectedTransferlist: '/admin/finance-accounts/rejected-tranferlist',
  // deleteaccountrequests: '/admin/delete-account-requests',
  // databasebackup: '/admin/setting/database-backup',
  // adminDevicemanagement: '/admin/management/device-management',
  // refundReport: '/admin/reports/refund-report',
  // vendorsList: '/services/vendors/list',
  // refundRequest: '/admin/finance-accounts/refund-request',
  // cacheSystem: '/admin/management/cachesystem',
  // signin: '/admin/signin',
  // userCustomers: '/admin/users/customers',
  // categoriesList: '/admin/categories/categories-list',
  // addPages: '/admin/pages/add-page',
  // EditPages: '/admin/edit-page',
  // viewService: '/admin/view-service',
  // permissions: '/admin/permissions',
  // offer: '/admin/marketing/offer',
  // adminNotification: '/admin/setting/notification',
  // pagesList: '/admin/pages-list',
  // pageList: '/admin/page-list',
  // addBlog: '/admin/blog/add-blog',
  // blogsCategories: '/admin/blog/blog-categories',
  // blogComments: '/admin/blog/blog-comments',
  // adminCustomerWallet: '/admin/reports/wallet',
  // addPage: '/admin/pages/add-pages',
  // Componentstest: '/pages/components/components-page',
};