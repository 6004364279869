interface APIRoutes {
    getAllCategories: string;
    listCategories: string;
    getServiceListbyId: string;
    getAllSubcategories: string;
    getAllServices: string;
    login: string,
    getBusinessBasedOnLocation: string;
    getbusinessdetails: string;
    getReportedIssueCategories: string;
    AddReportAnIssue: string;
    availableTimeSlot: string;
    getFamilyMembersList: string;
    checkfamilymember: string;
    familyMembersDelete: string;
    getAllFamilyMembersByUserId: string;
    familyMembersUpdate: string;
    getUserFavourites: string;
    getAllServiceSubscriptionPackages: string;
    getServiceSubscriptionPackageById: string;
    addServiceSubscription: string;
    deleteUserFavourites: string;
    addBookings: string;
    forgotPassword: string;
    resetPassword: string;
    changeOldPassword: string;
    addFamilyMember: string;
    getAllRelationships: string;
    getAllFamilyMembersDetailsById: string;
    getUserFavouritesLimited: string;
    userUpcomingBookingsLimited: string
    userAllbookingsLimited: string;
    getUserTotalCountDetails: string;
    getAvailableservicetime: string;
    getAllactiveservices: string;
    getServicesListByVendorId: string;
    getServiceDetailsById: string;
    serviceStatusUpdation: string;
    addWalkingbooking: string;
    registerVendor: string;
    registerotp: string;
    addProfile: string;

    getVendorTotalCountDetails: string;
    getVendorActiveServicesDetails: string;
    getVendorAllBookingsDetails: string;
    updatePersonalDetails: string;
    updateProfilePhoto: string;
    getVendorDetails: string;
    updateBusinessDetails: string;
    deleteService: string;
    apiAddService: string;
    updateService: string;
    saveVendordetails: string;
    updatebookStatus: string;
    getAllServiceBookingsById: string;
    getBusinessByCategoryAndSubcategory: string;
    checkVendoraccount: string,
    deleteAccount: string,
    checkUseraccount: string,
    checkvendordeactstatus: string;
    qrCodeRequest: string,
    cancelUserBookings: string;
    getRelatedBusiness: string;
    checkFavouriteStatus: string;
    addUpdateFavourites: string;
    resendOtp: string;
    reactivateUseraccount: string;
    contactUsMail: string;
    addkey:string;
    checkkey:string;
    canServiceEditDelete: string;
    cancelTokenRequest: string;
    captureSubscriptionPayment: string;
    addTrialServiceSubscription: string;
    checkServiceSubscriptionPurchaseEligibility: string;
    activeDowngradeSubscriptionPlan: string;
}

export const APIRoutes: APIRoutes = {
    getAllCategories: 'get-all-categories-and-subcategories',
    listCategories: 'get-all-categories',
    getAllSubcategories: 'get-all-subcategory-by-id',
    getAllServices: 'get-all-services',
    login: 'login',
    getBusinessBasedOnLocation: 'services-by-city',
    getbusinessdetails: 'businessdetails',
    availableTimeSlot: 'available-time-slot',
    getReportedIssueCategories: 'get-all-report-issue-categories',
    AddReportAnIssue: 'Add-Report-An-Issue',
    getServiceListbyId: 'get-services-list-by-vendor-id',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    changeOldPassword: 'change-password',
    getUserFavouritesLimited: 'getUserFavouritesLimited',
    userAllbookingsLimited: 'user-all-bookings-limited',
    userUpcomingBookingsLimited: 'user-upcoming-bookings-limited',
    getUserTotalCountDetails: 'getUserTotalCountDetails',
    getAvailableservicetime: 'available-time-slot',
    getAllactiveservices: 'get-all-active-services',
    getFamilyMembersList: 'get-all-family-members-details-by-userid',
    checkfamilymember: 'check-family-member-status',
    familyMembersDelete: 'family-members-delete',
    getAllFamilyMembersByUserId: 'get-all-family-members-by-userid',
    familyMembersUpdate: 'family-members-update',
    getAllFamilyMembersDetailsById: 'get-all-family-members-details-by-id',
    getUserFavourites: 'getUserFavourites',
    deleteUserFavourites: 'deleteUserFavourites',
    getAllServiceSubscriptionPackages: 'get-service-subscription',
    getServiceSubscriptionPackageById: 'get-service-subscription-by-id',
    addServiceSubscription: 'add-service-vendor-subscriptions',
    addBookings: 'booking',
    getServicesListByVendorId: 'get-services-list-by-vendor-id',
    getServiceDetailsById: 'get-service-details-by-id',
    serviceStatusUpdation: 'service-status-updation',
    addWalkingbooking: 'add-walkin-booking',
    registerVendor: 'register',
    addFamilyMember: 'add-family-member',
    getAllRelationships: 'get-all-relationships',
    registerotp: 'verifyotp',
    addProfile: 'update_personal_details',
    getVendorTotalCountDetails: 'vendor-total-count-details',
    getVendorActiveServicesDetails: 'vendor-active-service-details',
    getVendorAllBookingsDetails: 'vendor-all-bookings-limited',
    updatePersonalDetails: 'update_personal_details',
    updateProfilePhoto: 'update-user-profile-photo',
    getVendorDetails: 'get-user-details-by-id',
    updateBusinessDetails: 'update_business_details',
    deleteService: 'service-delete',
    apiAddService: 'addservice',
    updateService: 'updateservice',
    saveVendordetails: 'save_business_details',
    updatebookStatus: 'booking-status-update-by-id',
    getBusinessByCategoryAndSubcategory: 'get-categories-and-subcategories-based-business',
    getAllServiceBookingsById: 'vendor-service-booking-details',
    checkVendoraccount: 'checkVendorbookings',
    deleteAccount: 'delete-user-account',
    checkUseraccount: 'checkUserbookings',
    checkvendordeactstatus: 'checkVendoruserDeactstatus',
    qrCodeRequest: 'get-vendor-qr',
    cancelUserBookings: 'cancel-user-bookings',
    getRelatedBusiness: 'get-related-business',
    checkFavouriteStatus: 'check-favourite-status',
    addUpdateFavourites: 'add-update-favourites',
    resendOtp: 'regenerate-otp',
    reactivateUseraccount: 'reactivateUserAccount',
    contactUsMail: 'contact-us-mail',
    addkey:'add-key',
    checkkey:'check-key',
    canServiceEditDelete: 'can-edit-delete-service',
    cancelTokenRequest: 'cancelvendorbookings',
    captureSubscriptionPayment: 'capture-subscription-payment',
    addTrialServiceSubscription:'add-service-vendor-trial-subscriptions',
    checkServiceSubscriptionPurchaseEligibility:'check-service-subscription-purchase',
    activeDowngradeSubscriptionPlan:'active-downgrade-subscription-plan',
};
