import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import Footer from '../../../components/footer/footer';
import PagesAuthHeader from './common/header';
import { Nullable } from 'primereact/ts-helpers';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { TKN_DropdownInputvalue, TKN_TextInputreqired, TKN_TextInput, PhoneInputComponent, TKN_InputPlaceholderEdit } from '../../../components/inputfields/input';
import { CountryDropdown } from "react-country-region-selector";
import { validateMobileNumber, validateZipCodeByCountry } from "../../../../helper/validate";
import apiService from '../../../../api/authentication-service';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux'
import { setVendorRole } from '../../../../core/data/redux/action';
import { ButtonTextandIconReversed_BlueOverlayWithChildren, ButtonTextOnly_Blueoverlay, ButtonTextOnly_Whiteoverlay } from '../../../components/buttons/button';
import { Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import { CHAR_LIMIT_ADDRESS, CHAR_LIMIT_EMAIL_WEBSITE } from '../../../../constants/constants';
import en from '../../../../en/en';
import countries from 'world-countries/countries.json';
import { ChevronDown } from 'react-feather';

type ErrorMessages = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  gender?: string;
  address1?: string;
  address2?: string;
  state?: string;
  city?: string;
  postcode?: string;
  selectedCountry?: string;
};

type Option = {
  label: string;
  value: number;
};

const VendorProfile = () => {
  const routes = all_routes;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const userLoginDetails = useSelector((state: RootState) => state.userEmailAndPhone);
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [error, setError] = useState('');
  const [birthDate, setBirthDate] = useState<Nullable<Date>>(null);
  const [newbirthDate, setNewbirthDate] = useState<Date | null>(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [genderoption, setGenderoption] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<any>(userLoginDetails?.countryCodeDetails?.name ?? userLoginDetails?.countryCodeDetails);
  const [responseMessage, setResponseMessage] = useState('');
  const [countryError, setCountryError] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const vendorEmail = useSelector((state: RootState) => state.vendorEmail);
  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState(userLoginDetails.phone);
  const [selectedCountryPhone, setSelectedCountryPhone] = useState<any>(userLoginDetails?.countryCodeDetails?.name ?? userLoginDetails?.countryCodeDetails);
  const [email, setEmail] = useState('');
  const [countryDetails, setCountryDetails] = useState<any>(null);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const gender: Option[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Other', value: 3 }
  ];
  const resetResponseMessage = () => {
    setResponseMessage('');
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
    if (errors.firstName) {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: undefined }));
    }
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
    if (errors.lastName) {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: undefined }));
    }
  };

  const handleAddressChange = (value: string) => {
    if (value.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress1(value)
      errors.address1 = '';
    } else {
      errors.address1 = en.CHAR_LIMIT;
    }
    if (errors.address1) {
      setErrors((prevErrors) => ({ ...prevErrors, address1: undefined }));
    }
  };

  const handleAddress2Change = (value: string) => {
    if (value.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress2(value)
      errors.address2 = '';
    } else {
      errors.address2 = en.CHAR_LIMIT;
    }
    if (errors.address2) {
      setErrors((prevErrors) => ({ ...prevErrors, address2: undefined }));
    }
  };

  const handleStateChange = (value: string) => {
    setState(value);
    if (errors.state) {
      setErrors((prevErrors) => ({ ...prevErrors, state: undefined }));
    }
  };

  const handleCityChange = (value: string) => {
    setCity(value);
    if (errors.city) {
      setErrors((prevErrors) => ({ ...prevErrors, city: undefined }));
    }
  };

  const handlePostalcodeChange = (value: string) => {
    setPostcode(value);
    if (errors.postcode) {
      setErrors((prevErrors) => ({ ...prevErrors, postcode: undefined }));
    }
  };

  const handleSelectgender = (selectedGender: Option) => {
    setGenderoption(selectedGender.label);
    if (errors.gender) {
      setErrors((prevErrors) => ({ ...prevErrors, gender: undefined }));
    }
  };

  const handleCountryChange = (val: string) => {
    resetResponseMessage();
    if (!val) {
      setCountryError('Unable to select country. Please try again.');
      setSelectedCountry(null);
    } else {
      setCountryError('');
      setSelectedCountry(val);
      if (errors.selectedCountry) {
        setErrors((prevErrors) => ({ ...prevErrors, selectedCountry: undefined }));
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const Imagedata = reader.result as string;
        setSelectedImage(Imagedata);
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    }
  };

  const handleDobChange = (val: any) => {
    setNewbirthDate(val);
  };

  const handleSubmit = () => {
    const newErrors: ErrorMessages = {};
    const MobileNumber = phone.startsWith(countryCode) ? phone.slice(countryCode.length) : phone;

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!genderoption) newErrors.gender = 'Gender is required';

    if (!address1) newErrors.address1 = 'Address 1 is required';
    if (!state) newErrors.state = 'State is required';
    if (!city) newErrors.city = 'City is required';
    if (!postcode) {
      newErrors.postcode = 'Postcode is required';
    } else {
      const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, postcode);

      if (zipCodeValidationResult.status === 'false') {
        newErrors.postcode = zipCodeValidationResult.error;
      }
    };
    if (!selectedCountry) newErrors.selectedCountry = 'Country is required';

    const mobileNumberValidateResult = validateMobileNumber(MobileNumber, countryCode, 'Country code and phone number mismatch. Please verify.');
    if (mobileNumberValidateResult.status === 'false') {
      newErrors.phone = mobileNumberValidateResult.error;
    }

    if (selectedCountry != selectedCountryPhone && selectedCountry && selectedCountryPhone) {
      newErrors.phone = 'Country and phone number mismatch. Please verify.';
    }
    setErrors(newErrors);

    if (userLoginDetails?.countryCodeDetails?.name
      ? userLoginDetails?.countryCodeDetails?.name !== selectedCountry
      : userLoginDetails?.countryCodeDetails !== selectedCountry
    ) {
      newErrors.selectedCountry = 'Country and phone number mismatch. Please verify.'
    }


    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true)
      sendProfileRequest();
    } else {
      console.log("Validation failed");
    };
  };

  const sendProfileRequest = () => {
    const payload = profileRequest();

    apiService.addProfile(payload)
      .then((response: any) => {

        getProfileResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setError('Failed to fetch user details');
      });
  };

  const profileRequest = () => ({
    user_id: vendorEmail.userid,
    email: vendorEmail.email,
    first_name: firstName,
    last_name: lastName,
    gender: genderoption,
    dob: newbirthDate ? format(newbirthDate, 'yyyy-MM-dd') : null,
    address1: address1,
    address2: address2,
    country: selectedCountry,
    state: state,
    city: city,
    zipcode: postcode,
    profile_verified: 1,
    profile_photo: selectedImage ? selectedImage.split(',')[1] : '',
    country_details: userLoginDetails?.countryCodeDetails
  });

  const getProfileResponse = (response: any) => {
    setIsLoading(false);
    if (response) {

      const status = response?.status;
      if (status === true) {
        const responseData = response?.data;
        const user_role = response?.data?.userrole;
        const U_id = response?.data?.id;
        const userRoleDetails = {
          userrole: user_role,
          uid: U_id
        };

        if (user_role) {
          dispatch(setVendorRole(userRoleDetails));
          navigateTo(routes.vendorBusinessProfile);
        };
      };
    };
  };

  const handleCancelButton = () => {
    navigateTo(routes.login, { replace: true });
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const handlePhoneChange = (value: any, country: any) => {
    const dialCode = country.dialCode;
    const formattedDialCode = `+${dialCode}`;
    const CountryCode = country.countryCode;
    const countryCodeUppercase = CountryCode.toUpperCase();
    const CountryDetails = countries.find(country => country.cca2 === countryCodeUppercase);

    if (CountryDetails) {

      const transformedCountryDetails = {
        cca2: CountryDetails.cca2,
        currency: Object.keys(CountryDetails.currencies),
        callingCode: dialCode,
        region: CountryDetails.region,
        subregion: CountryDetails.subregion,
        flag: `flag-${CountryDetails.cca2.toLowerCase()}`,
        name: CountryDetails?.name?.common
      };

      setCountryDetails(transformedCountryDetails);
    }

    setPhone(`+${value}`);
    setCountryCode(formattedDialCode);
    setSelectedCountryPhone(country?.name);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    if (text.length <= CHAR_LIMIT_EMAIL_WEBSITE - 1) {
      setEmail(text)
      setEmailError('')
    }
    else {
      setEmailError(en.CHAR_LIMIT)
    }
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="widget-title">
                <h4>Add Profile</h4>
              </div>
              <h6 className="user-title">Profile Picture</h6>
              <div className="pro-picture w-100">
                <div className="pro-img">
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ objectFit: 'cover' }} />
                  ) : (
                    <ImageWithBasePath src='assets/img/profiles/avatar-01.avif' alt="user" style={{ objectFit: 'cover' }} />
                  )}
                </div>
                <div className="pro-info">
                  {selectedImage ? (
                    <div className="d-flex">
                      <ButtonTextandIconReversed_BlueOverlayWithChildren label={'Upload'} icon={'UploadCloud'} fullWidth={false} iconclassName='upload-icon-custom' className='upload-class-custom img-upload h-44px' >
                        <input type="file" onChange={handleFileChange} />
                      </ButtonTextandIconReversed_BlueOverlayWithChildren>
                      <ButtonTextOnly_Whiteoverlay label={'Remove'} fullWidth={false} onClick={handleRemoveImage} className='h-44px' />
                    </div>
                  ) : (
                    <div className="d-flex">
                      <ButtonTextandIconReversed_BlueOverlayWithChildren label={'Upload'} icon={'UploadCloud'} fullWidth={false} iconclassName='upload-icon-custom' className='upload-class-custom img-upload h-44px' >
                        <input type="file" onChange={handleFileChange} />
                      </ButtonTextandIconReversed_BlueOverlayWithChildren>
                    </div>)}
                  <p>
                    *image size should be at least 320px big,and less then 500kb.
                    Allowed files .png and .jpg.
                  </p>
                </div>
              </div>
              <h6 className="user-title">{en.VENDOR_INFO}</h6>
              <div className="general-info">
                <div className="row">
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleFirstNameChange} label="First Name" placeholder='Enter first name' />
                    {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                  </div>
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleLastNameChange} label="Last Name" placeholder='Enter last name' />
                    {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <TKN_InputPlaceholderEdit
                        placeholderText={'Enter your email'}
                        value={userLoginDetails.email}
                        readOnly={true}
                        onChange={handleEmailChange}
                        maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                        style={{ backgroundColor: '#f5f5f5' }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <PhoneInputComponent
                        handlePhoneChange={handlePhoneChange}
                        required={true}
                        value={phone}
                        readOnly={true}
                        error={errors.phone}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Gender<span className="text-danger">*</span></label>
                      <TKN_DropdownInputvalue
                        placeholderText="Select gender"
                        options={gender}
                        onSelect={handleSelectgender}
                        descriptionError={errors.gender}
                      />
                      {errors.gender && <div className="text-danger">{errors.gender}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Date of birth</label>
                      <div className="form-icon cus-profile">
                        <PrimeCalendar
                          value={birthDate}
                          minDate={new Date(1900, 0, 1)}
                          maxDate={new Date()}
                          readOnlyInput={true}
                          onChange={(e) => handleDobChange(e.value)}
                          placeholder="MM/DD/YYYY"
                          showIcon
                          inputClassName="p-inputtext"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="user-title">Address</h6>
                <div className="general-info">
                  <div className="row">
                    <div className="col-md-6">
                      <TKN_TextInputreqired maxLength={CHAR_LIMIT_ADDRESS} onChange={handleAddressChange} label="Address 1" placeholder='Enter your address' />
                      {errors.address1 && <div className="text-danger">{errors.address1}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInput maxLength={CHAR_LIMIT_ADDRESS} onChange={handleAddress2Change} label="Address 2" placeholder='Enter your address' />
                      {errors.address2 && <div className="text-danger">{errors.address2}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleStateChange} label="State" placeholder='Enter your state' />
                      {errors.state && <div className="text-danger">{errors.state}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleCityChange} label="City" placeholder='Enter your city' />
                      {errors.city && <div className="text-danger">{errors.city}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handlePostalcodeChange} label="Postal Code" placeholder='Enter postal code' />
                      {errors.postcode && <div className="text-danger">{errors.postcode}</div>}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Country<span className="text-danger">*</span></label>
                        <CountryDropdown
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          classes="form-control"
                          defaultOptionLabel="Select country"
                          priorityOptions={['United States']}
                        />
                        <ChevronDown className="dropdown-icon" style={{ marginTop: '20px' }} />
                      </div>
                      {errors.selectedCountry && <div className="text-danger">{errors.selectedCountry}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="acc-submit d-flex justify-content-end gap-30px">
                <ButtonTextOnly_Whiteoverlay
                  label={"Cancel"}
                  fullWidth={false}
                  onClick={handleCancelButton} />
                <ButtonTextOnly_Blueoverlay label={'Save'} fullWidth={false} isLoading={isLoading} disabled={isLoading} onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VendorProfile;
