import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import * as Icon from 'react-feather';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import './style.css'
import { ButtonTextOnly_Blueoverlay } from '../../../components/buttons/button';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';


const ProviderAppointmentSettings = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState(null);
  const [selectedValue3, setSelectedValue3] = useState(null);
  const user_obj = useSelector((state: RootState) => state.user);
  const vendor_obj = useSelector((state: RootState) => state.vendor);
  const dispatch = useDispatch();
  const routes = all_routes;
  const navigate = useNavigate();
  const value = [{ name: '30 Min' }, { name: '40 Min' }, { name: '50 Min' }];
  const value2 = [
    { name: '30 Minutes' },
    { name: '40 Minutes' },
    { name: '50 Minutes' },
  ];
  const value3 = [
    { name: '4 Months' },
    { name: '5 Months' },
    { name: '6 Months' },
  ];

  const handletoprovideredit = () => {
    navigate(routes.vendorProfileSettings)
  }
  const handletoproviderbusinessedit = () => {
    navigate(routes.vendorBusinessProfileEdit)
  }

  const formatDate = (dateStr: any) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [Year, Month, Day] = dateStr.split('-');
    const monthName = monthNames[parseInt(Month) - 1]
    return `${monthName}-${Day}-${Year}`
  }

  const formattedDOB = user_obj.dob ? formatDate(user_obj.dob) : null;
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            {/* Appointment Settings */}
            <div className="col-md-12 mx-auto">
              <div className="appointment-sec appointment-set-two">
                <div className='row'>
                  <div className="provider-subtitle">
                    <h6>Profile Details</h6>
                  </div>

                  {/* Personal Profile */}

                  <div className="col-md-6 col-appointmentcstm">
                    <div className="coupon-title">
                      <h4>Personal Profile</h4>
                    </div>
                    <div className="provider-info provider-detls">
                      <h2 className='para-elipses-text'>
                        {user_obj.first_name} {user_obj.last_name}
                      </h2>

                      <div className="row row-appointmentcstm">
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Email</h6>
                              <p className='para-elipses-text'>{user_obj.email}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Phone</h6>
                              <p className='para-elipses-text'>{user_obj.phone.startsWith(user_obj.country_code) ? user_obj.phone : `${user_obj.country_code}${user_obj.phone}`}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Gender</h6>
                              <p className='para-elipses-text'>{user_obj.gender}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Date of birth</h6>
                              <p className='para-elipses-text'>{formattedDOB}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Address</h6>
                              {user_obj.address2 ? (
                                <p className='para-elipses-text'>{user_obj.address1},</p>
                              ) : (
                                <p className='para-elipses-text'>{user_obj.address1}</p>
                              )}
                              <p className='para-elipses-text'>{user_obj.address2}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>State</h6>
                              <p className='para-elipses-text'>{user_obj.state}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>City</h6>
                              <p className='para-elipses-text'>{user_obj.city}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Postal Code</h6>
                              <p className='para-elipses-text'>{user_obj.zipcode}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Country</h6>
                              <p className='para-elipses-text'>{user_obj.country}</p>
                            </div>
                          </div>
                        </div>
                        <div className="acc-submit appoint-submit d-flex justify-content-end">
                          <ButtonTextOnly_Blueoverlay
                            label={'Edit'}
                            fullWidth={false}
                            onClick={handletoprovideredit} route={''} />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Business Profile */}

                  <div className="col-md-6 col-appointmentcstm">
                    <div className="coupon-title">
                      <h4>Business Profile</h4>
                    </div>
                    <div className="provider-info provider-detls">
                      <h2 className='para-elipses-text'>
                        {vendor_obj.bussiness_name}
                      </h2>

                      <div className="row row-appointmentcstm">
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Email</h6>
                              <p className='para-elipses-text'>{vendor_obj.vendor_email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Phone</h6>
                              <p className='para-elipses-text'>{vendor_obj.mobile.startsWith(vendor_obj.country_code) ? vendor_obj.mobile : `${vendor_obj.country_code}${vendor_obj.mobile}`}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Owner Name </h6>
                              <p className='para-elipses-text'>{vendor_obj.owner_name}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Website</h6>
                              <p className='para-elipses-text'>{vendor_obj.website}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Business Address</h6>
                              <p className='para-elipses-text'>{vendor_obj.bussiness_address}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>State</h6>
                              <p className='para-elipses-text'>{vendor_obj.State}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>City</h6>
                              <p className='para-elipses-text'>{vendor_obj.city}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Postal Code</h6>
                              <p className='para-elipses-text'>{vendor_obj.Zipcode}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="provide-box">
                            <div className="provide-info w-90-new">
                              <h6>Country</h6>
                              <p className='para-elipses-text'>{vendor_obj.country}</p>
                            </div>
                          </div>
                        </div>

                        <div className="acc-submit appoint-submit d-flex justify-content-end">
                          <ButtonTextOnly_Blueoverlay
                            label={'Edit'}
                            onClick={handletoproviderbusinessedit}
                            fullWidth={false} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Appointment Settings */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderAppointmentSettings;
