import React, { useState } from "react";
import './style.css'
import { ImageWithBasePath } from "../../../../core/img/ImageWithBasePath";
import { ButtonTextandIconReversed_BlueOverlay } from "../../../components/buttons/button";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../../core/data/routes/all_routes";


const SubscriptionPurchaseStatusSuccess = () => {
    const navigate = useNavigate();
    const route = all_routes;
    const handlegotoappClick = () => {
        navigate('#')
    }
    return (
        <div className="container d-flex justify-content-center align-items-center bookingdone-maincontainercustom" style={{ minHeight: "80vh" }}>
            <div className="row w-100">
                <div className="bookingdone-imagedivcustom">
                    <div className="booking-done bookingdone-imagedivchild-custom">
                        <ImageWithBasePath src={'assets/img/booking-done-success.png'} alt={'Payment success image'} className="w-100" />
                    </div>
                </div>
                <div className="bookingdone-bookingdetailscustom d-flex justify-content-center align-items-center text-center">
                    <div className="booking-done">
                        <h6 className="bookingdonecustom-text-heading">{'Payment Completed Successfully'}</h6>
                        <p className="custom-text-para">Package subscription has been successfully completed and activated.</p>
                        <div className="book-submit d-flex gap-30px justify-content-center">
                            <ButtonTextandIconReversed_BlueOverlay icon='ArrowLeftCircle' label={"Go to app"} fullWidth={false} onClick={handlegotoappClick} className="gap-10px h-44px" iconclassName="bookingone-icon-custom" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const SubscriptionPurchaseStatusFailed = () => {
    const navigate = useNavigate();
    const route = all_routes;
    const handlegotoappClick = () => {
        navigate('#')
    }
    return (
        <div className="container d-flex justify-content-center align-items-center bookingdone-maincontainercustom" style={{ minHeight: "80vh" }}>
            <div className="row w-100">
                <div className="bookingdone-imagedivcustom">
                    <div className="booking-done bookingdone-imagedivchild-custom">
                        <ImageWithBasePath src={'assets/img/payment-failed.png'} alt={'Payment failed image'} className="w-100" />
                    </div>
                </div>
                <div className="bookingdone-bookingdetailscustom d-flex justify-content-center align-items-center text-center">
                    <div className="booking-done">
                        <h6 className="bookingdonecustom-text-heading">{'Payment Failed'}</h6>
                        <p className="custom-text-para">Package subscription has been failed. Please try again.</p>
                        <div className="book-submit d-flex gap-30px justify-content-center">
                            <ButtonTextandIconReversed_BlueOverlay icon='ArrowLeftCircle' label={"Go to app"} fullWidth={false} onClick={handlegotoappClick} className="gap-10px h-44px" iconclassName="bookingone-icon-custom" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const SubscriptionPurchaseCancel = () => {
    const navigate = useNavigate();
    const route = all_routes;
    const handlegotoappClick = () => {
        navigate('#')
    }
    return (
        <div className="container d-flex justify-content-center align-items-center bookingdone-maincontainercustom" style={{ minHeight: "80vh" }}>
            <div className="row w-100">
                <div className="bookingdone-imagedivcustom">
                    <div className="booking-done bookingdone-imagedivchild-custom">
                        <ImageWithBasePath src={'assets/img/payment-failed.png'} alt={'Payment failed image'} className="w-100" />
                    </div>
                </div>
                <div className="bookingdone-bookingdetailscustom d-flex justify-content-center align-items-center text-center">
                    <div className="booking-done">
                        <h6 className="bookingdonecustom-text-heading">{'Payment Cancelled'}</h6>
                        <p className="custom-text-para">Package subscription has been Cancelled.</p>
                        <div className="book-submit d-flex gap-30px justify-content-center">
                            <ButtonTextandIconReversed_BlueOverlay icon='ArrowLeftCircle' label={"Go to app"} fullWidth={false} onClick={handlegotoappClick} className="gap-10px h-44px" iconclassName="bookingone-icon-custom" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export { SubscriptionPurchaseStatusSuccess, SubscriptionPurchaseStatusFailed, SubscriptionPurchaseCancel }