import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';
import { TagsInput } from 'react-tag-input-component';
import { Calendar } from 'primereact/calendar';
import * as Icon from 'react-feather';
import { Nullable } from 'primereact/ts-helpers';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from '../../../../core/img/ImageWithBasePath';
import {
  TKN_InputPlaceholder,
  TKN_DropdownInput,
  TKN_PhoneInputComponent,
  TKN_InputPlaceholderEdit,
  TKN_PhoneInputEditComponent,
  TKN_DropdownInputEdit,
} from '../../../components/inputfields/input';
import {
  ButtonTextandIconReversed_BlueOverlayWithChildren,
  ButtonTextOnly_Whiteoverlay,
  ButtonTextOnly_Blueoverlay
} from '../../../components/buttons/button';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import apiService from '../../../../api/authentication-service';
import { setUserDetails } from '../../../../core/data/redux/action';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { validateAddress, validateDropdown, validateEmptyValue, validateMobileNumber, validateUsername, validateZipCodeByCountry } from '../../../../helper/validate';
import { useNavigate } from 'react-router-dom';
import { CHAR_LIMIT_ADDRESS, CHAR_LIMIT_EMAIL_WEBSITE, CHAR_LIMIT_FIRST_NAME, CHAR_LIMIT_LAST_NAME, CHAR_LIMIT_PLACE, CHAR_ZIPCODE, WEB_BASE_URL } from '../../../../constants/constants';
import { CountryDropdown } from 'react-country-region-selector';
import { Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import { ChevronDown } from 'react-feather';
import en from '../../../../en/en';

const ProviderProfileSettings = () => {
  const [selectedGender, setGender] = useState(null);
  const [selectedCurrency, setCurrency] = useState(null);
  const [tags] = useState(['English', 'French']);
  const [date, setDate] = useState<Nullable<Date>>(null);
  const user_obj = useSelector((state: RootState) => state.user);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageUri, setSelectedImageUri] = useState<string>('');
  const profilepic = user_obj.profilephoto
  const defaultimg = { src: 'assets/img/profiles/avatar-01.avif' }


  const dispatch = useDispatch();
  const routes = all_routes;
  const navigate = useNavigate();
  useEffect(() => {
    if (user_obj.dob) {
      setDate(new Date(user_obj.dob));
    }
  }, [user_obj.dob]);
  useEffect(() => {
    setFirstName(user_obj.first_name);
    setLastName(user_obj.last_name);
    setEmail(user_obj.email);
    setPhone(user_obj.phone);
    setCountryCode(user_obj.country_code);
    setGenderupdate(user_obj.gender);
    setAddress1(user_obj.address1);
    setAddress2(user_obj.address2);
    setState(user_obj.state);
    setCity(user_obj.city);
    setZipcode(user_obj.zipcode);
    setCountry(user_obj.country);
    setCountryDetails(country_details_parsed_obj)
  }, [user_obj]);

  const gender = [
    { name: 'Select Gender' },
    { name: 'Male' },
    { name: 'Female' },
  ];
  const currency = [
    { name: 'Choose Currency' },
    { name: 'Eur' },
    { name: 'Aud' },
  ];

  const country_details_json_value = user_obj?.country_details
  const country_details_parsed_obj = country_details_json_value ? JSON.parse(country_details_json_value) : null;
  const Country_Name = country_details_parsed_obj?.name

  const [firstName, setFirstName] = useState(user_obj.first_name);
  const [lastName, setLastName] = useState(user_obj.last_name);
  const [email, setEmail] = useState(user_obj.email);
  const [phone, setPhone] = useState(user_obj.phone);
  const [countryCode, setCountryCode] = useState(user_obj.country_code);
  const [genderUpdate, setGenderupdate] = useState(user_obj.gender);
  const [address1, setAddress1] = useState(user_obj.address1);
  const [address2, setAddress2] = useState(user_obj.address2);
  const [state, setState] = useState(user_obj.state);
  const [city, setCity] = useState(user_obj.city);
  const [zipcode, setZipcode] = useState(user_obj.zipcode);
  const [country, setCountry] = useState(user_obj.country);
  const [countryDetails, setCountryDetails] = useState<any>();
  const [countryPhone, setCountryPhone] = useState<any>(Country_Name)

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [countryCodeError, setCountryCodeError] = useState('');
  const [genderUpdateError, setGenderUpdateError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [stateError, setStateError] = useState('');
  const [cityError, setCityError] = useState('');
  const [zipcodeError, setZipCodeError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [responsemessage, setResponseMessage] = useState('');
  const resetResponseMessage = () => {
    setResponseMessage
  }



  const getUpdatePayload = () => ({
    user_id: user_obj.user_id,
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone,
    country_code: countryCode,
    gender: genderUpdate,
    address1: address1,
    address2: address2,
    state: state,
    city: city,
    zipcode: zipcode,
    country: country,
    dob: date ? format(date, 'yyyy-MM-dd') : null,
    country_details: JSON.stringify(countryDetails),
  });
  const handleUpdateResponse = (response: any) => {
    setIsLoading(false);

    if (response?.data?.status === false) {
      console.error('Update failed:', response.data.message);
    } else if (response?.status === 200) {
      console.log('Update successful:', response.data);
      console.log('current First Name :', user_obj.first_name)
    } else {
      console.error('Unexpected response status:', response.status);
    }
  };

  const sendUpdateRequest = () => {
    const payload = getUpdatePayload();

    apiService.updatePersonalDetails(payload)
      .then((response: any) => {

        if (response.status === 200) {
          dispatch(setUserDetails(response.data.data));
          handleUpdateResponse(response);
        } else {
          console.error('Unexpected response status:', response.status);
        }
      })
      .catch((error: any) => {
        console.error('Error updating personal details:', error);
      });
  };
  const handleSave = () => {

    let isValid = true;
    const MobileNumber = phone.startsWith(countryCode) ? phone.slice(countryCode.length) : phone;
    const fieldToValidate = [
      { value: firstName, errorSetter: setFirstNameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'First name' },
      { value: lastName, errorSetter: setLastNameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'Last name' },
      { value: address1, errorSetter: setAddress1Error, validationFunction: [validateEmptyValue, validateAddress], fieldName: 'Address' },
      { value: country, errorSetter: setCountryError, validationFunction: [validateEmptyValue], fieldName: 'Country' },
      { value: state, errorSetter: setStateError, validationFunction: [validateEmptyValue], fieldName: 'State' },
      { value: city, errorSetter: setCityError, validationFunction: [validateEmptyValue], fieldName: 'City' },
      { value: gender, errorSetter: setGenderUpdateError, validationFunction: [validateDropdown], fieldName: 'Gender' },
    ]

    fieldToValidate.forEach((field => {
      for (const validationFunction of field.validationFunction) {
        const validationResult = validationFunction(field.value, field.fieldName);

        if (validationResult.status === 'false') {
          field.errorSetter(validationResult.error)
          isValid = false;
          break;
        }
      }
    }));

    const ZipcodeValidationResult = validateZipCodeByCountry(country, zipcode);
    if (ZipcodeValidationResult.status === 'false') {
      setZipCodeError(ZipcodeValidationResult.error)
      isValid = false;
    }
    else {
      setZipCodeError('')
    }

    const MobileNumberValidationResult = validateMobileNumber(MobileNumber, countryCode, 'Countrycode and phone number mismatch. Please verify.');
    if (MobileNumberValidationResult.status === 'false') {
      setPhoneError(MobileNumberValidationResult.error)
      isValid = false;
    }
    else {
      setPhoneError('')
    }

    if (country != countryPhone) {
      setPhoneError('Country and phone number mismatch. Please verify.')
      isValid = false;
    }
    else {
      setPhoneError('')
    }

    if (isValid) {
      setIsLoading(true);

      if (selectedImage) {
        const profileImage = selectedImage ? selectedImage.split(',')[1] : ''
        sendPhotoUpdateRequest(profileImage);
      };
      sendUpdateRequest();
      navigate('/vendors/edit-profile')
    }
  };

  const handleCancelbutton = () => {
    navigate('/vendors/edit-profile')
  };
  const handlefirstNamechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_LIMIT_FIRST_NAME - 1) {
      setFirstName(text);
      setFirstNameError('');
    }
    else {
      setFirstNameError(en.CHAR_LIMIT)
    }
  }

  const handlelastNamechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_LIMIT_LAST_NAME - 1) {
      setLastName(text);
      setLastNameError('');
    }
    else {
      setLastNameError(en.CHAR_LIMIT)
    }
  }

  const handleAddress1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress1(text);
      setAddress1Error('');
    }
    else {
      setAddress1Error(en.CHAR_LIMIT);
    }
  }

  const handleAddress2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_LIMIT_ADDRESS - 1) {
      setAddress2(text);
      setAddress2Error('');
    }
    else {
      setAddress2Error(en.CHAR_LIMIT);
    }
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_LIMIT_EMAIL_WEBSITE - 1) {
      setEmail(text);
      setEmailError('');
    }
    else {
      setEmailError(en.CHAR_LIMIT);
    }
  }

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_LIMIT_PLACE - 1) {
      setState(text);
      setStateError('');
    }
    else {
      setStateError(en.CHAR_LIMIT)
    };
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_LIMIT_PLACE - 1) {
      setCity(text);
      setCityError('');
    }
    else {
      setCityError(en.CHAR_LIMIT);
    };
  };

  const handlePostalcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (text.length <= CHAR_ZIPCODE) {
      setZipcode(text);
      setZipCodeError('');
    }
    else {
      setZipCodeError(en.CHAR_LIMIT);
    }
  };

  const handleCountryChange = (val: string) => {
    resetResponseMessage();
    if (!val) {
      setCountryError('Unable to select country. Please try again.');
      setCountry(null);
    } else {
      setCountryError('');
      setCountry(val);
    }
  };

  const Mobilevalidationcheck = (number: string, countrycode: string) => {
    const MobileNumberValidationResult = validateMobileNumber(number, countrycode, 'Countrycode and phone number mismatch. Please verify.');
    if (MobileNumberValidationResult.status === 'false') {
      setPhoneError(MobileNumberValidationResult.error)
    }
    else {
      setPhoneError('')
    }
  }

  const handlePhonechange = (phoneNumber: string, formattedDialCode: any, countryname: any, countrydetails: any) => {
    setCountryCode(formattedDialCode);
    setCountryPhone(countryname);
    setCountryDetails(countrydetails);
    const strippedphonenumber = phoneNumber.startsWith(formattedDialCode) ? phoneNumber.slice(formattedDialCode.length) : phoneNumber;
    Mobilevalidationcheck(strippedphonenumber, formattedDialCode);
    setPhone(strippedphonenumber);
  };
  const profileUpdateRequest = (base64Image: string) => ({
    user_id: user_obj.user_id,
    profilephoto: base64Image
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const Imagedata = reader.result as string;
        setSelectedImage(Imagedata);
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    };
  };

  const sendPhotoUpdateRequest = (base64Image: string) => {
    apiService.updateProfilePhoto(profileUpdateRequest(base64Image)).then((response) => {
      const responseData = response?.data;
      if (responseData) {
        const status = responseData?.status;
        if (status === true) {
          const profilePhoto = responseData?.data?.profilephoto;
          if (profilePhoto) {
            user_obj.profilephoto = profilePhoto;
            dispatch(setUserDetails(user_obj));
            setSelectedImageUri(WEB_BASE_URL + profilePhoto);
          };
        };
      };
    }).catch((error) => {
      console.log('error', error)
    });
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="widget-title">
              <h4 className='main-title-font'>Personal Profile</h4>
            </div>
            <h6 className="user-title">Profile Picture</h6>
            <div className="pro-picture w-100">
              <div className="pro-img">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ objectFit: 'cover' }}
                  />
                ) : (
                  <React.Fragment>
                    {profilepic ? (

                      <React.Fragment>
                        <ImageWithBasePathWithUrl
                          src={`${WEB_BASE_URL}${user_obj.profilephoto}`}
                          alt="user"
                          style={{ objectFit: 'cover' }}
                        />
                      </React.Fragment>) : (

                      <React.Fragment>
                        <ImageWithBasePath src={defaultimg.src} alt="user" style={{ objectFit: 'cover' }} />
                      </React.Fragment>)}
                  </React.Fragment>
                )}
              </div>
              <div className="pro-info">
                {selectedImage ? (
                  <div className="d-flex">
                    <ButtonTextandIconReversed_BlueOverlayWithChildren label={'Upload'} icon={'UploadCloud'} fullWidth={false} iconclassName='upload-icon-custom' className='upload-class-custom img-upload h-44px' >
                      <input type="file" onChange={handleFileChange} />
                    </ButtonTextandIconReversed_BlueOverlayWithChildren>
                    <ButtonTextOnly_Whiteoverlay label={'Remove'} fullWidth={false} onClick={handleRemoveImage} className='h-44px' />
                  </div>
                ) : (<div className="d-flex">
                  <ButtonTextandIconReversed_BlueOverlayWithChildren label={'Upload'} icon={'UploadCloud'} fullWidth={false} iconclassName='upload-icon-custom' className='upload-class-custom img-upload h-44px' >
                    <input type="file" onChange={handleFileChange} />
                  </ButtonTextandIconReversed_BlueOverlayWithChildren>
                </div>)}
                <p>
                  *image size should be at least 320px big,and less then 500kb.
                  Allowed files .png and .jpg.
                </p>
              </div>
            </div>
            <h6 className="user-title">{en.VENDOR_INFO}</h6>
            <div className="general-info">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <TKN_InputPlaceholderEdit
                      placeholderText={'Enter your first name'}
                      value={user_obj.first_name}
                      onChange={handlefirstNamechange}
                      maxLength={CHAR_LIMIT_FIRST_NAME}
                    />
                    {firstNameError !== '' && (
                      <div style={{ color: 'red' }}>{firstNameError}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <TKN_InputPlaceholderEdit
                      placeholderText={'Enter your last name'}
                      value={user_obj.last_name}
                      onChange={handlelastNamechange}
                      maxLength={CHAR_LIMIT_LAST_NAME}
                    />
                    {lastNameError !== '' && (
                      <div style={{ color: 'red' }}>{lastNameError}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <TKN_InputPlaceholderEdit
                      placeholderText={'Enter email address'}
                      value={user_obj.email}
                      onChange={handleEmailChange}
                      maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                      readOnly={true}
                      className={"disabled-button"}
                    />
                    {emailError !== '' && (
                      <div style={{ color: 'red' }}>{emailError}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <TKN_PhoneInputEditComponent onChange={handlePhonechange} required={true} inputvalue={phone.startsWith(countryCode) ? phone : `${countryCode}${phone}`} readOnly={false} error={phoneError} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <TKN_DropdownInputEdit
                      placeholderText={'Gender'}
                      options={['Male', 'Female']}
                      initialValue={user_obj.gender}
                      onChange={(value) => setGenderupdate(value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group new-margin-bottom">
                    <label className="col-form-label">Date of birth</label>
                    <div className="form-icon cus-profile">
                      <Calendar
                        id="dob"
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date()}
                        value={date}
                        onChange={(e) => {
                          const selectedDate = e.value;
                          setDate(selectedDate);
                        }}
                        placeholder="MM/DD/YYYY"
                        showIcon
                        readOnlyInput={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                </div>
              </div>
            </div>
            <h6 className="user-title">Address </h6>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    Address 1 <span className="text-danger">*</span>
                  </label>
                  <TKN_InputPlaceholderEdit
                    placeholderText={'Enter your address'}
                    value={user_obj.address1}
                    onChange={handleAddress1Change}
                    maxLength={CHAR_LIMIT_ADDRESS}
                  />
                  {address1Error !== '' && (
                    <div style={{ color: 'red' }}>{address1Error}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">Address 2 </label>
                  <TKN_InputPlaceholderEdit
                    placeholderText={'Enter your address'}
                    value={user_obj.address2}
                    onChange={handleAddress2Change}
                    maxLength={CHAR_LIMIT_ADDRESS}
                  />
                  {address2Error !== '' && (
                    <div style={{ color: 'red' }}>{address2Error}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    State <span className="text-danger">*</span>
                  </label>
                  <TKN_InputPlaceholderEdit
                    placeholderText={'Enter your state'}
                    value={user_obj.state}
                    onChange={handleStateChange}
                    maxLength={CHAR_LIMIT_PLACE}
                  />
                  {stateError !== '' && (
                    <div style={{ color: 'red' }}>{stateError}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    City <span className="text-danger">*</span>
                  </label>
                  <TKN_InputPlaceholderEdit
                    placeholderText={'Enter your city'}
                    value={user_obj.city}
                    onChange={handleCityChange}
                    maxLength={CHAR_LIMIT_PLACE}
                  />
                  {cityError !== '' && (
                    <div style={{ color: 'red' }}>{cityError}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    Postal Code <span className="text-danger">*</span>
                  </label>
                  <TKN_InputPlaceholderEdit
                    placeholderText={'Enter your postal code'}
                    value={user_obj.zipcode}
                    onChange={handlePostalcodeChange}
                    maxLength={CHAR_ZIPCODE}
                  />
                  {zipcodeError !== '' && (
                    <div style={{ color: 'red' }}>{zipcodeError}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">
                    Country <span className="text-danger">*</span>
                  </label>
                  <CountryDropdown
                    value={country}
                    onChange={handleCountryChange}
                    classes="form-control"
                    defaultOptionLabel="Select country"
                    priorityOptions={['United States']}
                  />
                  <ChevronDown className="dropdown-icon" style={{ marginTop: '20px' }} />
                  {countryError !== '' && (
                    <div className='errorText'>{countryError}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="acc-submit d-flex justify-content-end gap-30px">
              <ButtonTextOnly_Whiteoverlay
                label={'Cancel'}
                fullWidth={false}
                onClick={handleCancelbutton} />
              <ButtonTextOnly_Blueoverlay
                label={'Save'}
                fullWidth={false}
                isLoading={isLoading}
                disabled={isLoading}
                onClick={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ProviderProfileSettings;
