import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TKN_EmailInputwithcheck, TKN_PasswordInput, TKN_PhoneInputComponent } from '../../../components/inputfields/input';
import PagesAuthHeader from './common/header';
import { validateEmail, validateMobileNumber, validatepassword_ConfirmPassword, validatePassword_ConfirmPassword } from '../../../../../src/helper/validate';
import { all_routes } from '../../../../core/data/routes/all_routes';
import apiService from '../../../../api/authentication-service';
import { CHAR_LIMIT_NAME_PASSWORD, WEB_USER_ROLE_CUSTOMER } from '../../../../constants/constants';
import { setEmailAndPhone, setUserEmail, setVendorRole } from '../../../../core/data/redux/action';
import en from '../../../../en/en';
import { Spinner } from 'react-bootstrap';
import "./style/userSignUpStyle.css";
import { AccountHaveComponent, FormComponent, FormGroupComponent, LoginHeaderTitle, PasswordStrengthComponent, TextBoxWithTextComponent } from '../../../components/login/login_component';
import { ButtonTextOnly_Blueoverlay } from '../../../components/buttons/button';
import { TKN_Danger_message } from '../../../components/alert_messages/alert_messages';

interface FormErrors {
  email?: string;
  password?: string;
  general?: string;
  phone?: string;
  confirmPassword?: string;
}

const default_Country_Details = {
  callingCode: "1",
  cca2: "US",
  currency: ['USD'],
  flag: "flag-us",
  name: "United States",
  region: "Americas",
  subregion: "North America"
};


const UserSignup = () => {
  const routes = all_routes;
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const maxLength = CHAR_LIMIT_NAME_PASSWORD;
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [countryName, setCountryName] = useState<string>('');
  const [countryDetails, setCountryDetails] = useState<any>(default_Country_Details);
  const [errors, setErrors] = useState<FormErrors>({});
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordResponse, setPasswordResponse] = useState({
    passwordResponseText: '',
    passwordResponseKey: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [signUpTermsError, setSignUpTermsError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handlePhonechange = (value: any, formattedDialCode: any, countryname: any, countrydetails: any) => {
    setPhone(value);
    setCountryCode(formattedDialCode);
    setCountryName(countryname);
    setCountryDetails(countrydetails);

    if (value && errors.phone) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: undefined }));
    }
  };

  const onChangePassword = (password: string) => {
    setPassword(password);

    if (password.length <= 0) {
      setPasswordResponse({
        passwordResponseText: '',
        passwordResponseKey: '',
      });
    } else if (password.length < 8) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '0',
      });
    } else if (
      password.search(/[a-z]/) < 0 ||
      password.search(/[A-Z]/) < 0 ||
      password.search(/[0-9]/) < 0
    ) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '1',
      });
    } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '2',
      });
    } else {
      // All conditions met, clear the message
      setPasswordResponse({
        passwordResponseText: '',
        passwordResponseKey: '3',
      });
    }
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (email) {
      const validationResponse = validateEmail(email, 'Email');
      if (validationResponse.status === "false") {

        newErrors.email = validationResponse.error;
      };
    };

    const MobileNumber = phone.startsWith(countryCode) ? phone.slice(countryCode.length) : phone;
    const phoneValidation = validateMobileNumber(MobileNumber, countryCode, 'Country code and phone number mismatch. Please verify.');

    if (phoneValidation.status === 'false') {
      newErrors.phone = phoneValidation.error;
    }

    const ispasswordValid = validatepassword_ConfirmPassword(password, confirmPassword, 'Password');
    if (ispasswordValid["status"] === "false") {
      setPasswordError(ispasswordValid["passworderror"]);
      setConfirmPasswordError(ispasswordValid["confirm_passworderror"]);
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLoginClick = async (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      if (isChecked === true) {
        setSignUpTermsError("");
        sendValidityCheck();
      }
      else {
        setSignUpTermsError(en.SIGNUP_TERMS);
      };
    } else {
      setSignUpTermsError(en.SIGNUP_TERMS);
    };
  };

  const sendValidityCheck = () => {
    if (passwordError === '' && confirmPasswordError === '' && password === confirmPassword) {

      setIsValid(true);
      sendRegisterRequest();
    } else {

      setIsValid(false);
    }
  }

  const sendRegisterRequest = () => {
    setIsLoading(true);
    const payload = signupRequest();

    apiService.registerVendor(payload)
      .then((response: any) => {
        getsignupResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setErrors({ general: 'Failed to fetch user details' });
      });
  };

  const signupRequest = () => ({
    email: email.toLowerCase(),
    phone: phone,
    country_code: countryCode,
    country_details: JSON.stringify(countryDetails),
    country: countryName,
    password: password,
    c_password: confirmPassword,
    userrole: WEB_USER_ROLE_CUSTOMER,
  });

  const getsignupResponse = (response: any) => {
    setIsLoading(false);

    if (response) {

      const status = response?.status;
      if (status && status === true) {

        const userId = response?.data?.userid;
        const user_emaildetails = response?.data;
        if (userId) {
          dispatch(setEmailAndPhone({
            email: email,
            phone: phone,
            countryCodeDetails: countryDetails,
          }))
          dispatch(setUserEmail(user_emaildetails));
          dispatch(setVendorRole({
            userrole: WEB_USER_ROLE_CUSTOMER,
            id: user_emaildetails.userid,
          }));
          navigateTo(routes.emailOtp);
        }
      }
      else {
        if (response?.data.email) {
          setErrors({ general: response.data.email });
        }
        if (response?.data.phone) {
          setErrors({ general: response.data.phone });
        }
      }
    }
  }

  const handleKeyDown = (event: any) => {

    if (event.key === 'Enter') {
      handleLoginClick;
    }
  };

  const checkPasswordRequirements = (password: any) => {
    const hasMinimumLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password);
    return hasMinimumLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const HandleTextBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    setSignUpTermsError('');
  }

  const HandlePasswordonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);

    if (value.length < maxLength + 1) {
      setPasswordError('');
    } else {
      setPasswordError(en.CHAR_LIMIT);
    }
    onChangePassword(value);
  }
  const HandleConfirmPasswordonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmPassword(value);

    if (value.length < maxLength + 1) {
      setConfirmPasswordError('');
    } else {
      setConfirmPasswordError(en.CHAR_LIMIT);
    }
  }

  const HandleEmailPasswordonChange = (value: string) => {
    const LowerCValue = value.toLowerCase();
    setEmail(LowerCValue);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
    }
  }

  const handleCloseDanger_msg = () => {
    setErrors({ general: '' });
  }
  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <LoginHeaderTitle classname='vendor-title-custom' title='User Signup' />
                {errors.general && <TKN_Danger_message text={errors.general} onClick={handleCloseDanger_msg} />}
                <FormComponent>
                  <FormGroupComponent>
                    <TKN_EmailInputwithcheck
                      label='Email'
                      onChange={HandleEmailPasswordonChange}
                      required={true}
                    />
                    {errors.email && <div className="error" style={{ color: 'red' }}>{errors.email}</div>}
                  </FormGroupComponent>
                  <div className="row">
                    <div className="col-md-12">
                      <FormGroupComponent>
                        <div>
                          <TKN_PhoneInputComponent onChange={handlePhonechange} required={true} />
                          {errors.phone && <div className="error" style={{ color: 'red' }}>{errors.phone}</div>}
                        </div>
                      </FormGroupComponent>
                    </div>
                  </div>
                  <FormGroupComponent>
                    <label className="col-form-label d-block">
                    </label>
                    <div className="pass-group">
                      <TKN_PasswordInput
                        label={'Password'}
                        value={password}
                        onChange={HandlePasswordonChange}
                        required={true}
                      />
                      {passwordError && <div className="error" style={{ color: 'red' }}>{passwordError}</div>}
                      {password !== '' && !passwordError && !checkPasswordRequirements(password) && (
                        <div style={{ color: 'red' }}>
                          {en.PASSWORD_REQUIREMENTS}
                        </div>
                      )}
                    </div>
                    <PasswordStrengthComponent PasswordResponse={passwordResponse} />
                  </FormGroupComponent>
                  <FormGroupComponent>
                    <label className="col-form-label d-block">
                    </label>
                    <div className="pass-group">
                      <TKN_PasswordInput
                        label={'Confirm Password'}
                        value={confirmPassword}
                        onChange={HandleConfirmPasswordonChange}
                        required={true}
                      />
                      {confirmPasswordError && <div className="error" style={{ color: 'red' }}>{confirmPasswordError}</div>}
                    </div>
                  </FormGroupComponent>
                  <FormGroupComponent>
                    <TextBoxWithTextComponent handleOnChange={HandleTextBoxChange} isChecked={isChecked}>
                      {en.BY_CLICKING_SIGNUP}&nbsp;
                      <a
                        href={routes.termsCondition}
                        className="link-text"
                        target='_blank'
                        rel="noreferrer"
                      >
                        {en.TERMS}
                      </a>,&nbsp;
                      <a
                        href={routes.privacyPolicy}
                        className="link-text"
                        target='_blank'
                        rel="noreferrer"
                      >
                        {en.PRIVACY_POLICY}
                      </a>, {en.COOKIES_POLICY}.
                    </TextBoxWithTextComponent>
                    {!isChecked && signUpTermsError && <div className="error" style={{ color: 'red' }}>{signUpTermsError}</div>}
                  </FormGroupComponent>
                  <ButtonTextOnly_Blueoverlay label={'Signup'} fullWidth={false} type='submit' className='w-100 login-btn signupheight-custom btn-custom-center' onKeydown={handleKeyDown} onClick={handleLoginClick} disabled={isLoading} isLoading={isLoading} />
                  <AccountHaveComponent title='Already have an account?' linktext='Sign In' route={routes.login} />
                </FormComponent>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default UserSignup;
